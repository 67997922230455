import React from 'react';
import Logo from '../assets/images/logo.svg';
import { inject, observer, Provider } from 'mobx-react';

import { Button, TextField } from '@material-ui/core';
import { InjectedComponent } from '../components/InjectedComponent';
import { AUTH_STORE, InjectedAuthStore } from '../mobx/stores/authStore';
import { api } from '../lib/api';
import * as s from './SignupPage.module.scss';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link } from 'react-router-dom';

interface State {
  repeatPassword: string;
  repeatPasswordError: boolean;
  captchaToken: null | string;
  submitError: null | string;
  submitSuccess: null | string;
  submitDisabled: boolean;
}

const recaptchaRef = React.createRef();

@inject(AUTH_STORE)
@observer
export class SignupPage extends InjectedComponent<
  InjectedAuthStore,
  { history: any },
  State
> {
  public state: State = {
    submitError: null,
    submitSuccess: null,
    repeatPassword: '',
    repeatPasswordError: false,
    captchaToken: null,
    submitDisabled: false,
  };

  public async signup(
    email: string,
    password: string,
    captchaToken: string | null,
  ) {
    if (this.state.submitDisabled) {
      return;
    }

    if (!captchaToken) {
      this.setState({ submitError: 'Captcha was niet succesvol' });
      return;
    }

    if (this.state.repeatPassword !== password) {
      this.setState({ submitError: 'Wachtwoord komt niet overeen' });
      return;
    }

    this.setState({ submitDisabled: true, submitError: null });

    try {
      await api.req('signup', {
        body: {
          email,
          password,
          captchaToken,
        },
      });

      const waitTillRedirect = 5;

      this.setState({ repeatPassword: '' });
      this.injected.authStore.signupEmail = '';
      this.injected.authStore.signupPassword = '';

      // Prefill email so the user doesn't have to enter it on the login page.
      this.injected.authStore.setLoginEmail(email);

      this.setState({
        submitSuccess: `Uw account is aangemaakt! (U wordt binnen ${waitTillRedirect} seconden doorgestuurd naar de login pagina)`,
      });

      setTimeout(() => {
        this.setState({ submitDisabled: false });
        this.props.history.push('/');
      }, waitTillRedirect * 1000);
    } catch (e) {
      this.setState({ submitDisabled: false });
      this.setState({
        submitError:
          'Er is iets mis gegaan. Neem a.u.b. contact op met support.',
      });
    }
  }

  public onCaptcha(value: string) {
    if (value) {
      this.setState({ captchaToken: value });
    }
  }

  public render() {
    return (
      <>
        <div className={s.signup}>
          <div className={s.signup__holder}>
            <img className={s.signup__login_logo} src={Logo} alt="Logo" />

            <form
              className={s.signup__form}
              onSubmit={(e) => {
                e.preventDefault();
                this.signup(
                  this.injected.authStore.signupEmail,
                  this.injected.authStore.signupPassword,
                  this.state.captchaToken,
                );
              }}
            >
              <div className={s.signup__text}>
                <p>
                  We zijn gestart met de ontwikkeling van de publieke beta. Het
                  is nu mogelijk om gratis een account aan te maken.
                  <br></br>
                  Na het maken van uw account krijgt u toegang tot uw
                  persoonlijke portfolio dashboard.
                </p>
                <br></br>
              </div>

              {this.state.submitError && (
                <div className={s.signup__error}>
                  <p>{this.state.submitError}</p>
                </div>
              )}

              {this.state.submitSuccess && (
                <div className={s.signup__success}>
                  <p>{this.state.submitSuccess}</p>
                </div>
              )}

              <TextField
                defaultValue={this.injected.authStore.signupEmail}
                onChange={(event) =>
                  this.injected.authStore.setSignupEmail(event.target.value)
                }
                id="email"
                label="Email"
                variant="outlined"
                autoCapitalize="off"
                type="email"
                fullWidth={true}
                InputLabelProps={{
                  classes: { root: s.label, focused: s.labelFocused },
                }}
                InputProps={{
                  classes: {
                    root: s.inputRoot,
                    focused: s.inputRootFocused,
                  },
                }}
              />
              <TextField
                defaultValue={this.injected.authStore.signupPassword}
                onChange={(event) =>
                  this.injected.authStore.setSignupPassword(event.target.value)
                }
                id="password"
                label="Wachtwoord"
                variant="outlined"
                type="password"
                fullWidth={true}
                InputLabelProps={{
                  classes: { root: s.label, focused: s.labelFocused },
                }}
                InputProps={{
                  classes: {
                    root: s.inputRoot,
                    focused: s.inputRootFocused,
                  },
                }}
              />
              <TextField
                defaultValue={this.state.repeatPassword}
                onChange={(event) => {
                  const newValue = event.target.value;

                  this.setState({
                    repeatPasswordError:
                      newValue !== '' &&
                      newValue !== this.injected.authStore.signupPassword,
                    repeatPassword: newValue,
                  });
                }}
                error={this.state.repeatPasswordError}
                id="password"
                label="Wachtwoord (herhalen)"
                variant="outlined"
                type="password"
                fullWidth={true}
                InputLabelProps={{
                  classes: { root: s.label, focused: s.labelFocused },
                }}
                InputProps={{
                  classes: {
                    root: s.inputRoot,
                    focused: s.inputRootFocused,
                  },
                }}
              />

              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6LfgeDQaAAAAAL9vC7NALUK5pGxqxqnXFb0-wiWx"
                onChange={(value) => this.onCaptcha(value)}
              />

              <label htmlFor="submit" className={`button ${s.button}`}>
                Account aanmaken
              </label>
              <input id="submit" type="submit" hidden={true} value="Submit" />
              <br />
              <br />
              <Link to="/">
                <span>Heeft u al een account? Naar Login</span>
              </Link>
            </form>
          </div>
        </div>
      </>
    );
  }
}
