import { inject, observer } from 'mobx-react';
import React from 'react';
import {
  InjectedPortfolioStore,
  PORTFOLIO_STORE,
} from '../../mobx/stores/portfolioStore';
import { InjectedComponent } from '../InjectedComponent';
import * as _ from 'lodash';
import { Button, CircularProgress, MenuItem, Select } from '@material-ui/core';
import s from './PortfolioBenchmarks.module.scss';
import { socket } from '../../lib/socket';
import { DateTime } from 'luxon';
import { reaction } from 'mobx';
import {
  InjectedSocketStore,
  SOCKET_STORE,
} from '../../mobx/stores/socketStore';
import {
  VictoryAxis,
  VictoryBar,
  VictoryContainer,
  VictoryLabel,
  VictoryLegend,
  VictoryLine,
  VictoryTooltip,
} from 'victory';
import currency from 'currency.js';

interface State {
  benchmarks: any[];
  loading: boolean;
  chartData?: {
    dailyValueData: { x: string; y: number }[];
    dailyValueDomain: { min: number; max: number };
    dailyReturnData: { x: string; y: number }[];
    tickValues: DateTime[];
  };
  activeMonth: number;
  activeYear: number;
}

const euroDecimalFormatter = (value) =>
  currency(value, { separator: '.', decimal: ',', symbol: '€ ' });

const euroFormatter = new Intl.NumberFormat(undefined, {
  style: 'currency',
  currency: 'EUR',
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

@inject(PORTFOLIO_STORE, SOCKET_STORE)
@observer
export class PortfolioBenchmarksComponent extends InjectedComponent<
  InjectedPortfolioStore & InjectedSocketStore,
  {},
  State
> {
  public state: State = {
    benchmarks: [],
    loading: true,
    activeMonth: DateTime.local()
      .minus({ month: 1 })
      .set({ day: 1 })
      .get('month'),
    activeYear: DateTime.local().get('year'),
  };

  public reactions: any[] = [];
  public years: number[] = [];
  public months: string[] = [];

  public componentDidMount() {
    for (let i = 1; i <= 12; i++) {
      this.months.push(
        DateTime.local()
          .set({ month: i, day: 1 })
          .setLocale('nl')
          .toLocaleString({ month: 'long' }),
      );
    }

    for (let i = 0; i <= 10; i++) {
      this.years.push(
        DateTime.local()
          .set({ month: 1, day: 1 })
          .minus({ year: i })
          .get('year'),
      );
    }

    this.reactions.push(
      reaction(
        () => this.injected.socketStore.isConnected,
        async (connected) => {
          if (connected) {
            try {
              await this.getBenchmarksForMonth(
                this.state.activeMonth,
                this.state.activeYear,
              );
            } catch (e) {
              console.error('error while getting benchmarks', e);
            }
          }
        },
        {
          fireImmediately: true,
        },
      ),
    );
  }

  public componentWillUnmount() {}

  public async getBenchmarksForMonth(month: number, year: number) {
    const date = DateTime.local().set({
      year,
      month,
      day: 1,
    });

    if (date.toMillis() > DateTime.local().toMillis()) {
      return;
    }

    this.setState({ loading: true, activeYear: year, activeMonth: month });

    const benchmarks = await socket.emit('benchmarks', {
      from: date.toISODate(),
      till: date.set({ day: date.daysInMonth }).toISODate(),
    });

    this.injected.portfolioStore.setBenchmarks(benchmarks);
    this.setState({ loading: false });
  }

  public formatGraphData() {
    if (!this.injected.portfolioStore.benchmarks) {
      return {
        dailyReturnData: [],
        dailyValueData: [],
        dailyValueDomain: { min: 0, max: 1 },
        dailyReturnDomain: { min: 0, max: 1 },
        tickValues: [],
      };
    }

    const dailyValueData = this.injected.portfolioStore.benchmarks.portfolioBenchmark.perDay.map(
      (day) => {
        const change = (day.portfolioWeightedReturnRate * 100 - 100).toFixed(2);
        let changeFormatted = change;

        if (day.portfolioWeightedReturnRate * 100 - 100 > 0) {
          changeFormatted = '+' + change;
        }

        return {
          y: day.totalValueInEur,
          x: day.date,
          transactions: day.transactions,
          label: `${DateTime.fromISO(day.date)
            .setLocale('nl')
            .toLocaleString()} \n ${euroFormatter.format(
            day.totalValueInEur,
          )} \n ${changeFormatted}%`,
        };
      },
    );

    const dailyValueDomain = {
      min: dailyValueData.length > 0 ? _.minBy(dailyValueData, 'y')!.y : 0,
      max: dailyValueData.length > 0 ? _.maxBy(dailyValueData, 'y')!.y : 0,
    };

    const dailyReturnData = this.injected.portfolioStore.benchmarks.portfolioBenchmark.perDay.map(
      (day) => {
        return { y: day.portfolioWeightedReturnRate * 100 - 100, x: day.date };
      },
    );
    const dailyReturnDomain = {
      min: dailyReturnData.length > 0 ? _.minBy(dailyReturnData, 'y')!.y : 0,
      max: dailyReturnData.length > 0 ? _.maxBy(dailyReturnData, 'y')!.y : 0,
    };

    const tickValues = this.injected.portfolioStore.benchmarks.portfolioBenchmark.perDay.map(
      (day) => DateTime.fromISO(day.date),
    );

    return {
      dailyReturnData,
      dailyValueData,
      dailyValueDomain,
      dailyReturnDomain,
      tickValues,
    };
  }

  public async onMonthSelect(selectedMonth: number) {
    await this.getBenchmarksForMonth(selectedMonth, this.state.activeYear);
  }

  public async onYearSelect(selectedYear: number) {
    await this.getBenchmarksForMonth(this.state.activeMonth, selectedYear);
  }

  public renderSummary() {
    if (!this.injected.portfolioStore.benchmarks) {
      return;
    }

    let transactionCount = <></>;
    let transactionCosts = <></>;
    let transactionPurchases = <></>;
    let transactionSold = <></>;

    const transactions = _.flatten(
      this.injected.portfolioStore.benchmarks.portfolioBenchmark.perDay.map(
        (dailyBenchmark) => dailyBenchmark.transactions,
      ),
    );

    if (transactions.length > 0) {
      const costs = -_.sum(
        transactions.map((transaction) => transaction.brokerFees),
      );
      const purchasedSymbols = Array.from(
        new Set(
          transactions
            .filter((transaction, _i) => transaction.amount > 0)
            .map((transaction) => transaction.symbol),
        ),
      );
      const soldSymbols = Array.from(
        new Set(
          transactions
            .filter((transaction, _i) => transaction.amount < 0)
            .map((transaction) => transaction.symbol),
        ),
      );

      if (purchasedSymbols.length > 0) {
        transactionPurchases = (
          <span>
            <u>Aangekocht</u> ={' '}
            {purchasedSymbols
              .map((purchasedSymbols) => purchasedSymbols)
              .join(', ')}
          </span>
        );
      }

      if (soldSymbols.length > 0) {
        transactionSold = (
          <span>
            <u>Verkocht</u> ={' '}
            {soldSymbols.map((soldSymbol) => soldSymbol).join(', ')}
          </span>
        );
      }

      transactionCount = (
        <span>
          <u>Aantal transacties</u> = {transactions.length}
        </span>
      );
      transactionCosts = (
        <span>
          <u>Transactie kosten</u> = {euroDecimalFormatter(costs).format()}
        </span>
      );
    }

    return (
      <>
        <div className={s.summary}>
          <span>
            <u>Rendement over periode</u> ={' '}
            <>
              {(
                this.injected.portfolioStore.benchmarks.portfolioBenchmark
                  .periodReturnRate *
                  100 -
                100
              ).toFixed(2)}
            </>
            %
          </span>
          {transactionCount}
          {transactionCosts}
          {transactionPurchases}
          {transactionSold}
        </div>
      </>
    );
  }

  public render() {
    const graphData = this.formatGraphData();

    const typogrophy = {
      fontFamily: 'Arial, Helvetica, sans-serif',
      padding: 5,
      fontSize: 12,
    };

    const axisTheme = {
      axis: {
        style: {
          tickLabels: typogrophy,
          axisLabel: typogrophy,

          grid: {
            stroke: '#e2e2e2',
            strokeWidth: '0.5',
            // stroke: 'none',
            pointerEvents: 'painted',
          },
        },
      },
    };

    return (
      <>
        <div className={s.container}>
          <div className={s.holder}>
            <header>
              <h3>Groei over tijd</h3>{' '}
            </header>

            <br></br>
            <div className={s.config}>
              <div className={s.config__top}>
                <div>
                  <span className={s.periodLabel}>Periode</span>
                  <Select
                    labelId="month"
                    id="month"
                    value={this.state.activeMonth}
                    onChange={(event) =>
                      this.onMonthSelect(event.target.value as number)
                    }
                  >
                    {this.months.map((month, i) => (
                      <MenuItem key={i + 1} value={i + 1}>
                        {month}
                      </MenuItem>
                    ))}
                  </Select>{' '}
                  <Select
                    labelId="year"
                    id="year"
                    value={this.state.activeYear}
                    onChange={(event) =>
                      this.onYearSelect(event.target.value as number)
                    }
                  >
                    {this.years.map((year, i) => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </div>

                <div className={s.config__top__buttons}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      const date = DateTime.local()
                        .set({
                          year: this.state.activeYear,
                          month: this.state.activeMonth,
                        })
                        .minus({ month: 1 });

                      this.getBenchmarksForMonth(
                        date.get('month'),
                        date.get('year'),
                      );
                    }}
                  >
                    Vorige
                  </Button>{' '}
                  <Button
                    variant="outlined"
                    onClick={() => {
                      const date = DateTime.local()
                        .set({
                          year: this.state.activeYear,
                          month: this.state.activeMonth,
                        })
                        .plus({ month: 1 });

                      this.getBenchmarksForMonth(
                        date.get('month'),
                        date.get('year'),
                      );
                    }}
                  >
                    Volgende
                  </Button>
                </div>
              </div>
              {this.renderSummary()}
            </div>
            <div className={s.graph}>
              {this.state.loading && (
                <CircularProgress
                  style={{ width: 15, height: 15 }}
                  color="inherit"
                />
              )}
              {!this.state.loading && (
                <>
                  {/* <div className={s.summary}>
                    <span>
                      Rendement over periode:{' '}
                      {(
                        this.injected.portfolioStore.benchmarks
                          .portfolioBenchmark.periodReturnRate *
                          100 -
                        100
                      ).toFixed(2)}
                      %
                    </span>
                  </div> */}

                  {graphData.dailyValueData.length > 0 && (
                    <VictoryContainer
                      height={310}
                      width={450}
                      responsive={true}
                    >
                      <VictoryLabel
                        x={12}
                        y={40}
                        text={'Vermogen'}
                        style={{ ...typogrophy, fontWeight: 'bold' }}
                      />

                      <VictoryLabel
                        x={385}
                        y={45}
                        text={'Rendement'}
                        style={{ ...typogrophy, fontWeight: 'bold' }}
                      />

                      <g transform={'translate(20, 20)'}>
                        <VictoryAxis
                          scale="time"
                          theme={{
                            ...axisTheme,
                            axis: {
                              ...axisTheme.axis,
                              style: {
                                ...axisTheme.axis.style,
                                grid: { stroke: 'none' },
                              },
                            },
                          }}
                          fixLabelOverlap={false}
                          standalone={false}
                          tickValues={graphData.tickValues}
                          tickFormat={(date: DateTime) => {
                            return date ? date.get('day') : 0;
                          }}
                          domainPadding={{ x: [9, 6] }}
                          tickComponent={<VictoryLabel angle={-45} />}
                          tickLabelComponent={
                            <VictoryLabel
                              style={{ fontSize: 9, letterSpacing: 0 }}
                            />
                          }
                        />
                        <VictoryAxis
                          dependentAxis
                          domain={[0, graphData.dailyValueDomain.max]}
                          tickFormat={(num: number) =>
                            euroFormatter.format(num)
                          }
                          offsetX={50}
                          orientation="left"
                          standalone={false}
                          theme={axisTheme}
                        />
                        <VictoryAxis
                          dependentAxis
                          domain={[
                            graphData.dailyReturnDomain.min - 2,
                            graphData.dailyReturnDomain.max + 2,
                          ]}
                          orientation="right"
                          standalone={false}
                          tickFormat={(num: number) => `${num}%`}
                          theme={{
                            axis: {
                              ...axisTheme.axis,
                              style: {
                                ...axisTheme.axis.style,
                                grid: { stroke: 'none' },
                              },
                            },
                          }}
                        />

                        <VictoryLine
                          data={graphData.dailyReturnData}
                          domain={{
                            y: [
                              graphData.dailyReturnDomain.min - 2,
                              graphData.dailyReturnDomain.max + 2,
                            ],
                          }}
                          // interpolation="catmullRom"
                          standalone={false}
                          style={{ data: { stroke: '#f39e2d' } }}
                          domainPadding={{ x: [4, 4] }}
                          animate={{
                            duration: 50,
                          }}
                        />
                        <VictoryBar
                          style={{
                            data: {
                              fill: ({ datum }) => {
                                if (datum.transactions.length > 0) {
                                  const transactionSum = -_.sum(
                                    datum.transactions.map(
                                      (transaction) =>
                                        transaction.netTotalPrice,
                                    ),
                                  );
                                  if (transactionSum > 0) {
                                    return '#00008082';
                                  } else if (transactionSum < 0) {
                                    return '#ff6345ba';
                                  } else {
                                    return '#FFBF69C4';
                                  }
                                } else {
                                  return '#FFBF69C4';
                                }
                              },
                            },
                          }}
                          data={graphData.dailyValueData}
                          standalone={false}
                          barRatio={0.5}
                          alignment="end"
                          labelComponent={
                            <VictoryTooltip
                              dy={20}
                              dx={15}
                              centerOffset={{ x: -20, y: -20 }}
                              style={{
                                ...typogrophy,
                              }}
                              flyoutStyle={{ fill: 'white' }}
                            />
                          }
                          // animate={{
                          //   duration: 100,
                          // }}
                          // domain={{
                          //   y: [
                          //     graphData.dailyReturnDomain.min ,
                          //     graphData.dailyValueDomain.max
                          //   ]
                          // }}
                          minDomain={{
                            y: 0,
                          }}
                          maxDomain={{
                            y: graphData.dailyValueDomain.max,
                          }}
                          domainPadding={{ x: [30, 5] }}
                        />
                      </g>
                      <VictoryLegend
                        x={0}
                        y={290}
                        standalone={false}
                        orientation="horizontal"
                        gutter={20}
                        labelComponent={
                          <VictoryLabel
                            style={{ ...typogrophy, fontSize: 11 }}
                          />
                        }
                        colorScale={['#00008082', '#ff6345ba']}
                        data={[
                          { name: 'Aankoop datum' },
                          { name: 'Verkoop datum' },
                        ]}
                      />
                    </VictoryContainer>
                  )}
                </>
              )}
            </div>
          </div>
          {/* <span>
                Portfolio return{' '}
                {
                  this.injected.portfolioStore.benchmarks.portfolioBenchmark
                    .periodReturnRate
                }
              </span>
              <br></br> <br></br>
              <span>Indicatoren</span> <br></br>
              {this.injected.portfolioStore.benchmarks.marketBenchmarks.map(
                (marketBenchmark) => {
                  return (
                    <div key={marketBenchmark.name}>
                      <span>
                        {marketBenchmark.name} {marketBenchmark.returnRate}
                      </span>{' '}
                      <br></br>{' '}
                    </div>
                  );
                },
              )} */}
        </div>
      </>
    );
  }
}
