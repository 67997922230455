import { action, computed, makeObservable, observable } from 'mobx';

class AuthStore {
  @observable
  token: string = '';

  @observable
  signupEmail: string;

  @observable
  signupPassword: string;

  @observable
  loginEmail: string =
    process.env.NODE_ENV === 'development' ? 'admin@stockdash.nl' : '';

  @observable
  loginPassword: string = process.env.NODE_ENV === 'development' ? 'admin' : '';

  @computed
  get isLoggedIn() {
    return !!this.token;
  }

  @action
  setLoginEmail(loginEmail: string) {
    this.loginEmail = loginEmail.trim();
  }

  @action
  setLoginPassword(loginPassword: string) {
    this.loginPassword = loginPassword.trim();
  }

  @action
  setSignupEmail(signupEmail: string) {
    this.signupEmail = signupEmail.trim();
  }

  @action
  setSignupPassword(signupPassword: string) {
    this.signupPassword = signupPassword.trim();
  }

  @action
  setToken(token: string) {
    this.token = token;
  }

  constructor() {
    makeObservable(this);
  }
}

export const authStore = new AuthStore();
export const AUTH_STORE = 'authStore';

export interface InjectedAuthStore {
  authStore: AuthStore;
}
