import { InjectedComponent } from './components/InjectedComponent';
import { getToken } from './services/auth';
import { inject, observer } from 'mobx-react';
import { InjectedAuthStore, AUTH_STORE } from './mobx/stores/authStore';
import { USER_STORE, InjectedUserStore } from './mobx/stores/userStore';

@inject(AUTH_STORE, USER_STORE)
@observer
export default class Init extends InjectedComponent<
  InjectedAuthStore & InjectedUserStore
> {
  public state = {
    isReady: false,
  };

  public async componentDidMount() {
    // moment.locale('nl');
    // TODO: Make dynamic based on user
    await this.init();
  }

  private async init() {
    const token = await getToken();
    this.injected.authStore.setToken(token);
    this.setState({ isReady: true });
  }

  public render() {
    // Wait till init() is done & User is loaded
    if (!this.state.isReady) {
      return <p>Waiting</p>;
    } else {
      const { children } = this.props;
      return children;
    }
  }
}
