import { reaction } from 'mobx';
import { userStore } from '../stores/userStore';

// Sync storage with store
reaction(
  () => userStore.user,
  async (user) => {
    if (user) {
      // await saveToStorage('user', user);
      // await setUser();
      //   if (!uiStore.loadedWithInternet && uiStore.isConnected) {
      //     refreshUser().catch(error =>
      //       logger.error('Error on initial refresh of user', { error }),
      //     );
      //   }
    } else {
      // await removefromStorage('user');
    }
  },
);
