import { inject, observer } from 'mobx-react';
import React from 'react';
import {
  InjectedPortfolioStore,
  PortfolioHolding,
  PORTFOLIO_STORE,
} from '../../mobx/stores/portfolioStore';
import { InjectedComponent } from '../InjectedComponent';
import * as _ from 'lodash';
import { CircularProgress, Paper, Tab, Tabs } from '@material-ui/core';
import s from './HoldingValueDistribution.module.scss';
import { PieChart } from './pieChart/PieChart';

interface State {
  valuePieData: any[];
  sectorPieData: any[];
  industryPieData: any[];
  loading: boolean;
  activeTab: number;
}

@inject(PORTFOLIO_STORE)
@observer
export class HoldingValueDistribution extends InjectedComponent<
  InjectedPortfolioStore,
  {},
  State
> {
  public state: State = {
    valuePieData: [],
    sectorPieData: [],
    industryPieData: [],
    loading: true,
    activeTab: 0,
  };

  public initialized = false;
  public updateInterval: NodeJS.Timeout;

  public componentDidMount() {
    this.setState({
      valuePieData: this.getValuePieData(this.injected.portfolioStore.holdings),
      sectorPieData: this.getSectorPieData(
        this.injected.portfolioStore.holdings,
        'sector',
      ),
      industryPieData: this.getSectorPieData(
        this.injected.portfolioStore.holdings,
        'industry',
      ),
    });

    this.updateInterval = setInterval(() => {
      if (
        this.injected.portfolioStore.holdings.length === 0 ||
        this.injected.portfolioStore.holdings.find(
          (holding) => typeof holding.totalValue !== 'undefined',
        )
      ) {
        this.setState({
          valuePieData: this.getValuePieData(
            this.injected.portfolioStore.holdings,
          ),
          sectorPieData: this.getSectorPieData(
            this.injected.portfolioStore.holdings,
            'sector',
          ),
          industryPieData: this.getSectorPieData(
            this.injected.portfolioStore.holdings,
            'industry',
          ),
          loading: false,
        });
      }
    }, 2000);
  }

  public componentWillUnmount() {
    clearInterval(this.updateInterval);
  }

  public getSectorPieData(holdings: any[], filter: 'sector' | 'industry') {
    const totalValueSum = _.sum(
      holdings
        .map((holding: PortfolioHolding) => holding.totalValue || 0)
        .filter((v) => v),
    );

    const groupedBySector = _.groupBy(
      holdings,
      'stock.yahooAssetProfile.' + filter,
    );

    const values: { x: string; y: number; percentage: number }[] = [];

    for (let sector in groupedBySector) {
      const holdings = groupedBySector[sector];
      const sectorValue = _.sum(holdings.map((holding) => holding.totalValue));
      const percentage = (100 / totalValueSum) * (sectorValue || 0) || 0;

      values.push({
        x: sector === 'null' ? 'Onbekend' : sector,
        y: sectorValue,
        percentage,
      });
    }

    return values.sort((a, b) => {
      if (b.percentage > a.percentage) {
        return -1;
      } else if (a.percentage > b.percentage) {
        return 1;
      }
      return 0;
    });
  }

  public getValuePieData(holdings: any[]) {
    const totalValueSum = _.sum(
      holdings
        .map((holding: PortfolioHolding) => holding.totalValue || 0)
        .filter((v) => v),
    );

    return holdings
      .map((holding: PortfolioHolding) => {
        const percentage =
          (100 / totalValueSum) * (holding.totalValue || 0) || 0;
        return {
          x: holding.symbol,
          y: holding.totalValue || 0,
          percentage,
        };
      })
      .sort((a, b) => {
        if (b.percentage > a.percentage) {
          return -1;
        } else if (a.percentage > b.percentage) {
          return 1;
        }
        return 0;
      });
  }

  public render() {
    return (
      <>
        <div className={s.container}>
          {this.state.loading && (
            <CircularProgress
              style={{ width: 15, height: 15 }}
              color="inherit"
            />
          )}
          {!this.state.loading && (
            <>
              <div className={s.holder}>
                {this.state.activeTab === 0 && (
                  <PieChart data={this.state.valuePieData} />
                )}
                {this.state.activeTab === 1 && (
                  <PieChart data={this.state.sectorPieData} />
                )}
                {this.state.activeTab === 2 && (
                  <PieChart data={this.state.industryPieData} />
                )}
              </div>
              <div className={s.tabs_container}>
                <Paper className={s.tabs} square variant="outlined">
                  <Tabs
                    value={this.state.activeTab}
                    onChange={(_e, value) => {
                      this.setState({ activeTab: value });
                    }}
                    indicatorColor="primary"
                    textColor="primary"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    centered={true}
                    variant="fullWidth"
                    className={s.tabs__inner}
                  >
                    <Tab
                      label="Waarde"
                      disableRipple
                      className={s.tab__button}
                    />
                    <Tab
                      label="Sector"
                      disableRipple
                      className={s.tab__button}
                    />
                    <Tab
                      label="Branch"
                      disableRipple
                      className={s.tab__button}
                    />
                  </Tabs>
                </Paper>
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}
