import React from 'react';
import Logo from '../assets/images/logo.svg';
import { inject, observer } from 'mobx-react';

import { TextField } from '@material-ui/core';
import { InjectedComponent } from '../components/InjectedComponent';
import { AUTH_STORE, InjectedAuthStore } from '../mobx/stores/authStore';
import { api } from '../lib/api';
import * as s from './LoginPage.module.scss';
import { Link } from 'react-router-dom';

@inject(AUTH_STORE)
@observer
export class LoginPage extends InjectedComponent<InjectedAuthStore> {
  public async login(email: string, password: string) {
    const response = await api.req('login', {
      body: {
        email,
        password,
      },
    });

    if (response) {
      this.injected.authStore.setToken(response.data.token);
      this.injected.authStore.setLoginPassword('');
    }
  }

  public render() {
    return (
      <>
        <div className={s.login}>
          <div className={s.holder}>
            <img className={s.login_logo} src={Logo} alt="Logo" />
            <form
              onSubmit={(e) => {
                e.preventDefault();
                this.login(
                  this.injected.authStore.loginEmail,
                  this.injected.authStore.loginPassword,
                );
              }}
            >
              <TextField
                className={s.textfield}
                defaultValue={this.injected.authStore.loginEmail}
                onChange={(event) =>
                  this.injected.authStore.setLoginEmail(event.target.value)
                }
                id="email"
                label="Email"
                variant="outlined"
                autoCapitalize="off"
                type="email"
                InputLabelProps={{
                  classes: { root: s.label, focused: s.labelFocused },
                }}
                InputProps={{
                  classes: {
                    root: s.inputRoot,
                    focused: s.inputRootFocused,
                  },
                }}
              />
              <TextField
                defaultValue={this.injected.authStore.loginPassword}
                onChange={(event) =>
                  this.injected.authStore.setLoginPassword(event.target.value)
                }
                id="password"
                label="Wachtwoord"
                variant="outlined"
                type="password"
                InputLabelProps={{
                  classes: { root: s.label, focused: s.labelFocused },
                }}
                InputProps={{
                  classes: {
                    root: s.inputRoot,
                    focused: s.inputRootFocused,
                  },
                }}
              />
              <label htmlFor="submit" className={`button ${s.button}`}>
                Inloggen
              </label>
              <input id="submit" type="submit" hidden={true} value="Submit" />
              <br></br>
              <Link to="/signup">
                <span>Account aanmaken</span>
              </Link>
            </form>
          </div>
        </div>
      </>
    );
  }
}
