import { computed, observable, action, makeObservable } from 'mobx';

class SocketStore {
  @observable
  _connected: boolean = false;

  @computed
  get isConnected() {
    return !!this._connected;
  }

  @action
  setConnection(connected: boolean) {
    this._connected = connected;
  }

  constructor() {
    makeObservable(this);
  }
}

export const socketStore = new SocketStore();
export const SOCKET_STORE = 'socketStore';

export interface InjectedSocketStore {
  socketStore: SocketStore;
}
