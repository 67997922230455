import { reaction } from 'mobx';
import { updateUser } from '../../services/user';
import { userStore } from '../stores/userStore';
import { socket } from '../../lib/socket';
import { authStore } from '../stores/authStore';
import { removefromStorage, saveToStorage } from '../../lib/storage';

// Sync storage with store
reaction(
  () => authStore.token,
  async (token) => {
    if (token) {
      await saveToStorage('token', {
        token,
      });

      try {
        await updateUser(token);
      } catch (e) {
        // Reset token if this request fails. (Will result in Login redirect)
        authStore.setToken('');
        userStore.setUser(undefined);
        return;
      }
      await socket.connect('app', token);
    } else {
      await removefromStorage('token');
      userStore.setUser(undefined);

      await socket.disconnect();
    }
  },
);

// async function registerForPushNotificationsAsync() {
//   if (Constants.isDevice) {
//     const { status: existingStatus } = await Permissions.getAsync(
//       Permissions.NOTIFICATIONS,
//     );
//     let finalStatus = existingStatus;

//     if (existingStatus !== 'granted') {
//       const { status } = await Permissions.askAsync(Permissions.NOTIFICATIONS);
//       finalStatus = status;
//     }
//     if (finalStatus !== 'granted') {
//       // status is probably undetermined.
//       console.error(finalStatus);
//       return;
//     }

//     const pushState = await getFromStorage<{
//       token: string;
//       provider: 'expo';
//       synced_at: number;
//     }>('push');

//     const token = await Notifications.getExpoPushTokenAsync();

//     if (!pushState || !pushState.synced_at) {
//       // send to backend
//       const connectPush = await api.req('userConnectPush', {
//         token: authStore.token,
//         body: {
//           token,
//           provider: 'expo',
//         },
//       });

//       if (connectPush.status === 'ok') {
//         await saveToStorage('push', {
//           token,
//           provider: 'expo',
//           synced_at: Date.now(),
//         });
//       }
//     } else if (pushState && pushState.token !== token) {
//       // update token
//       const connectPush = await api.req('userConnectPush', {
//         token: authStore.token,
//         body: {
//           token,
//           provider: 'expo',
//           previousToken: pushState.token,
//         },
//       });

//       if (connectPush.status === 'ok') {
//         await removefromStorage('push');
//         await saveToStorage('push', {
//           token,
//           provider: 'expo',
//           synced_at: Date.now(),
//         });
//       }
//     }
//   } else {
//     // alert('Must use physical device for Push Notifications');
//   }

//   if (Platform.OS === 'android') {
//     Notifications.createChannelAndroidAsync('default', {
//       name: 'default',
//       sound: true,
//       priority: 'max',
//       vibrate: [0, 250, 250, 250],
//     });
//   }
// }
