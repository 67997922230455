import { api } from '../lib/api';
import { getFromStorage } from '../lib/storage';
import { authStore } from '../mobx/stores/authStore';

export async function login() {
  const result = await api.req('login', {
    body: {
      email: authStore.loginEmail,
      password: authStore.loginPassword,
    },
  });

  if (!result || !result.data) {
    throw new Error('Error during login');
  }

  authStore.setToken(result.data.token);
  return;
}

export async function getToken() {
  const tokenData = await getFromStorage('token');
  return tokenData.token;
}

export async function logout() {
  // if (Constants.isDevice) {
  //   const pushState = await getFromStorage('push');
  //   if (pushState && pushState.synced_at) {
  //     const result = await api.req('disableUserConnectPush', {
  //       token: authStore.token,
  //       body: {
  //         provider: 'expo',
  //         token: pushState.token,
  //       },
  //     });
  //     if (result.status === 'ok') {
  //       await removefromStorage('push');
  //     }
  //   }
  // }

  // authStore.setLoginPassword('');
  authStore.setToken('');
}
