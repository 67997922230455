import { api } from '../lib/api';
import { userStore } from '../mobx/stores/userStore';

export async function updateUser(token: string) {
  try {
    const result = await api.req('me', { token });
    userStore.setUser(result?.data);
  } catch (e) {
    const status = e.data.statusCode;
    if (status === 401) {
      throw new Error('unauthorized');
    } else {
      throw e;
    }
  }
}

export async function updateOnboarding(token: string) {
  const result = await api.req('onboard', { token });
}
