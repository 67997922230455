export type SocketDefinition = {
  portfolio: {
    message: 'portfolio';
  };
  subscribeRealtimePrices: {
    message: 'subscribe_realtime_prices';
  };
  benchmarks: {
    message: 'benchmarks';
  };
};

export class SocketResponse<T> {
  status: 'ok';
  data: T;
}

export class SocketErrorResponse {
  status: 'error';
  message: string;
}

export const socketDefinition: SocketDefinition = {
  portfolio: {
    message: 'portfolio',
  },
  subscribeRealtimePrices: {
    message: 'subscribe_realtime_prices',
  },
  benchmarks: {
    message: 'benchmarks',
  },
};
