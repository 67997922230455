import { ApiDefinition, apiDefinition, Method } from './definition';

export type Options = {
  body: any;
};

export type GenericBody = { [key: string]: string };
export type GenericParams = { [key: string]: string };

export type TokenMethod = 'HEADER' | 'QS';

export type ApiRequestOptions = {
  token: string | undefined;
  tokenMethod: TokenMethod | undefined;
  body: GenericBody;
  qs: GenericParams;
};

export abstract class Api {
  constructor(protected baseUrl: string) {}

  protected abstract _req(
    url: string,
    method: Method,
    options: ApiRequestOptions,
  ): Promise<any>;

  public req<T extends keyof ApiDefinition>(
    route: T,
    options: {
      token?: string;
      tokenMethod?: TokenMethod;
    } & Pick<ApiDefinition[T], 'qs'> &
      Pick<ApiDefinition[T], 'body'> &
      Pick<ApiDefinition[T], 'params'>,
  ): Promise<ApiDefinition[T]['response']> {
    const reqDefinition = apiDefinition[route];
    let token: string | undefined;
    let tokenMethod: TokenMethod | undefined;

    if (reqDefinition.auth) {
      token = options.token;
      tokenMethod = options.tokenMethod || 'HEADER';
    }

    let uri: string = reqDefinition.uri;

    if (options && options.params) {
      Object.keys(options.params).forEach((param) => {
        uri = uri.replace(new RegExp(`:${param}`, 'g'), options.params![param]);
      });
    }

    return this._req(`${this.baseUrl}/${uri}`, reqDefinition.method, {
      body: options.body!,
      qs: options.qs!,
      token,
      tokenMethod,
    });
  }
}
