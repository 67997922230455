import { WebSocket } from '../shared/socket/WebSocket';
import { socketStore } from '../mobx/stores/socketStore';
import { config } from '../config';

export const socket = new WebSocket(config.apiEndpoint, {
  onConnect: () => {
    socketStore.setConnection(true);
  },
  onDisconnect: () => {
    socketStore.setConnection(false);
  },
});
