import { InjectedComponent } from '../InjectedComponent';
import { CircularProgress } from '@material-ui/core';
import { api } from '../../lib/api';
import { inject, observer } from 'mobx-react';
import { AUTH_STORE, InjectedAuthStore } from '../../mobx/stores/authStore';
import s from './DeGiroFileImport.module.scss';
import React from 'react';
import Error from '@material-ui/icons/Error';
import { getPortfolio } from '../../services/portfolio';
import {
  InjectedPortfolioStore,
  PORTFOLIO_STORE,
} from '../../mobx/stores/portfolioStore';
import { socket } from '../../lib/socket';

interface ImportState {
  loading: boolean;
  error: string | null;
}

interface ImportProps {
  hideStatus?: boolean;
  onSuccess: () => void;
  onFailure: (error: any) => void;
  onClick?: () => void;
}

@inject(AUTH_STORE, PORTFOLIO_STORE)
@observer
export class DegiroFileImport extends InjectedComponent<
  InjectedAuthStore & InjectedPortfolioStore,
  ImportProps,
  ImportState
> {
  public state: ImportState = {
    loading: false,
    error: null,
  };

  public async onFileSelect(event) {
    if (this.props.onClick) {
      this.props.onClick();
    }

    this.setState({
      loading: true,
    });

    const file = event.target.files[0];

    const data = new FormData();
    data.append('file', file);

    try {
      await api.req('degiroImport', {
        token: this.injected.authStore.token,
        body: data,
      });

      this.setState({ error: null, loading: false });

      const portfolio = await getPortfolio();
      this.injected.portfolioStore.setHoldings(portfolio);
      await socket.emit('subscribeRealtimePrices');

      this.props.onSuccess();
    } catch (e) {
      this.setState({
        error: 'Er is iets mis gegaan',
      });
      // implement on fail
      this.props.onFailure(e);
      event.target.value = null;
    }

    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 500);
  }

  public render() {
    const unique = Date.now();
    return (
      <>
        <input
          id={`import-${unique}`}
          type="file"
          name="DeGiro"
          hidden={true}
          onChange={(event) => this.onFileSelect(event)}
        />
        <label
          className={`button ${s.button__import}`}
          htmlFor={`import-${unique}`}
        >
          +DeGiro Import
        </label>
        {!this.props.hideStatus && (
          <div className={s.import__status}>
            <div className={s.import__status__progress}>
              {this.state.loading && (
                <>
                  <CircularProgress
                    style={{ width: 15, height: 15 }}
                    color="inherit"
                  />
                  <span>Laden van transacties...</span>
                </>
              )}
              {!this.state.loading && this.state.error && (
                <>
                  <Error style={{ width: 15, height: 15 }} />
                  <span>{this.state.error}</span>
                </>
              )}
            </div>
          </div>
        )}

        {/* <Button variant="contained" color="primary">
          DeGiro
        </Button> */}
      </>
    );
  }
}
