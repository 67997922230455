import { reaction } from 'mobx';
import { getPortfolio } from '../../services/portfolio';
import { portfolioStore } from '../stores/portfolioStore';
import { socketStore } from '../stores/socketStore';

reaction(
  () => socketStore.isConnected,
  async (isConnected) => {
    if (isConnected) {
      const portfolio = await getPortfolio();
      portfolioStore.setHoldings(portfolio);
    } else {
      portfolioStore.setHoldings([]);
    }
  },
);
