import { inject, observer } from 'mobx-react';

import { InjectedComponent } from '../components/InjectedComponent';
import { AUTH_STORE, InjectedAuthStore } from '../mobx/stores/authStore';
import { DegiroFileImport } from '../components/deGiroFileImport/DeGiroFileImport';
import { socket } from '../lib/socket';
import { reaction } from 'mobx';
import { InjectedSocketStore, SOCKET_STORE } from '../mobx/stores/socketStore';
import s from './ConsolePage.module.scss';
import { Ticker } from '../services/prices';
import { PortfolioBenchmarksComponent } from '../components/portfolioBenchmarks/PortfolioBenchmarks';

interface State {
  degiroImportError: string;
  ticks: Ticker[];
  ticksReceived: number;
}

@inject(AUTH_STORE, SOCKET_STORE)
@observer
export class ConsolePage extends InjectedComponent<
  InjectedAuthStore & InjectedSocketStore,
  {},
  State
> {
  public state: State = { degiroImportError: '', ticks: [], ticksReceived: 0 };
  public reactions: any[] = [];
  public subscribed = false;

  public async subscribeRealtimePrices() {
    if (!this.subscribed) {
      await socket.on('price', (tick: Ticker) => {
        this.state.ticks.unshift(tick);

        if (this.state.ticks.length > 50) {
          this.state.ticks.pop();
        }

        this.setState({
          ticks: this.state.ticks,
          ticksReceived: this.state.ticksReceived + 1,
        });
      });
      await socket.emit('subscribeRealtimePrices');
      this.subscribed = true;
    }
  }

  public componentDidMount() {}

  public render() {
    return (
      <>
        <div className="row">
          <div className="col__12">
            <h3>Importer</h3>
            <br></br>
            <DegiroFileImport onFailure={(e) => {}} onSuccess={() => {}} />
            <br></br>

            <h3>Realtime prices</h3>
            <br></br>
            <pre className={s.prices}>
              {this.state.ticks.map((tick) => {
                return `${tick.yahooSymbol} ${tick.price} ${tick.changePercent} ${tick.exchangeKey}\n`;
              })}
            </pre>
            <span>Received ticks: {this.state.ticksReceived}</span>
            <br></br>
            <button
              className="button"
              onClick={() => this.subscribeRealtimePrices()}
            >
              Subscribe to prices
            </button>

            <div>
              <br></br>
              <PortfolioBenchmarksComponent />
            </div>
          </div>
        </div>
      </>
    );
  }
}
