import { inject, observer } from 'mobx-react';
import { InjectedComponent } from './components/InjectedComponent';
import { AUTH_STORE, InjectedAuthStore } from './mobx/stores/authStore';
import { LoginPage } from './pages/LoginPage';
import Logo from './assets/images/logo.svg';
import * as _ from 'lodash';
import { InjectedSocketStore, SOCKET_STORE } from './mobx/stores/socketStore';
import currency from 'currency.js';
import { DegiroFileImport } from './components/deGiroFileImport/DeGiroFileImport';
import { InjectedUserStore, USER_STORE } from './mobx/stores/userStore';
import { logout } from './services/auth';
import { Route, Switch, Link } from 'react-router-dom';
import { PortfolioPage } from './pages/PortfolioPage';
import { ConsolePage } from './pages/ConsolePage';
import {
  InjectedPortfolioStore,
  PortfolioHolding,
  PORTFOLIO_STORE,
} from './mobx/stores/portfolioStore';
import { Onboarding } from './components/onboarding/Onboarding';

interface State {
  degiroImportError: string;
  holdings: PortfolioHolding[];
  stats: {
    totalValue: null | string;
    totalProfit: null | string;
    holdingCount: null | string;
  };
}

const euroFormatter = (value) =>
  currency(value, { separator: '.', decimal: ',', symbol: '€ ' });

@inject(AUTH_STORE, SOCKET_STORE, USER_STORE, PORTFOLIO_STORE)
@observer
export class Main extends InjectedComponent<
  InjectedAuthStore &
    InjectedSocketStore &
    InjectedUserStore &
    InjectedPortfolioStore,
  {},
  State
> {
  public state: State = {
    degiroImportError: '',
    holdings: [],
    stats: {
      totalProfit: null,
      totalValue: null,
      holdingCount: null,
    },
  };

  public componentDidMount() {
    this.setState({
      holdings: this.injected.portfolioStore.holdings,
      stats: {
        totalProfit: this.getTotalProfit(),
        totalValue: this.getTotalValue(),
        holdingCount: this.getAssetCount(),
      },
    });

    setInterval(() => {
      this.setState({
        holdings: this.injected.portfolioStore.holdings,
      });
      this.setState({
        stats: {
          totalProfit: this.getTotalProfit(),
          totalValue: this.getTotalValue(),
          holdingCount: this.getAssetCount(),
        },
      });
    }, 1000);
  }

  public logout() {
    return logout();
  }

  public getTotalValue() {
    const total =
      _.sum(
        this.state.holdings
          .map((holding: PortfolioHolding) =>
            holding.totalValue ? holding.totalValue : 0,
          )
          .filter((v) => v),
      ) || 0;

    return euroFormatter(total).format();
  }

  public getAssetCount() {
    return (
      this.state.holdings.reduce((sum, curr) => {
        if (curr.amount !== 0) {
          return sum + 1;
        } else {
          return sum;
        }
      }, 0) || 0
    ).toString();
  }

  public getTotalProfit() {
    const total =
      this.state.holdings.reduce((sum, curr) => {
        return sum + (curr.profit || 0);
      }, 0) || 0;

    return euroFormatter(total).format();
  }

  public render() {
    return (
      <>
        {!this.injected.authStore.token && <LoginPage />}

        {this.injected.authStore.token && this.injected.userStore.user && (
          <>
            <div className="main-container">
              <div className="content-container">
                <aside className="main-navigation">
                  <header>
                    <div className="logo">
                      <Link to="/">
                        <img src={Logo} alt="Logo" />
                      </Link>
                    </div>
                  </header>
                  <nav>
                    <div className="nav-divider"></div>
                    <ul className="main">
                      {this.injected.userStore.user.admin && (
                        <li>
                          <Link to="/console">Admin</Link>
                        </li>
                      )}
                    </ul>
                    <div className="nav-divider"></div>
                    <ul className="bottom">
                      <li>
                        <div className="profile-image">
                          <span>{this.injected.userStore.user.firstname}</span>
                        </div>
                        <a href="#" onClick={this.logout}>
                          Logout
                        </a>
                      </li>
                    </ul>
                  </nav>
                </aside>

                <div className="content-area">
                  <header className="quick-view">
                    <div className="summary">
                      <div className="total">
                        <div className="number">
                          <span>{this.state.stats.totalValue}</span>
                        </div>
                        <div className="description">
                          <span>Portfolio waarde</span>
                        </div>
                      </div>
                      <div className="profit">
                        <div className="number">
                          <span>{this.state.stats.totalProfit}</span>
                        </div>
                        <div className="description">
                          <span>Winst</span>
                        </div>
                      </div>
                      <div className="properties">
                        <div className="number">
                          <span>{this.state.stats.holdingCount}</span>
                        </div>
                        <div className="description">
                          <span>Eigendommen</span>
                        </div>
                      </div>
                      <div className="import">
                        <DegiroFileImport
                          onFailure={(e) => {
                            if (e.statusText === 'Conflict') {
                              this.setState({
                                degiroImportError:
                                  'Dit bestand is reeds geimporteerd',
                              });
                            } else {
                              this.setState({
                                degiroImportError: 'Er is iets mis gegaan',
                              });
                            }

                            console.error(e);
                          }}
                          onSuccess={() => {}}
                        />
                      </div>
                    </div>
                    <div className="row"></div>
                  </header>
                  <section className="view">
                    <Switch>
                      <Route exact path="/" component={PortfolioPage} />
                      <Route exact path="/console" component={ConsolePage} />
                    </Switch>
                  </section>
                </div>
              </div>
            </div>

            <Onboarding></Onboarding>
          </>
        )}
      </>
    );
  }
}
