import { inject, observer } from 'mobx-react';
import { InjectedComponent } from '../components/InjectedComponent';
import { AUTH_STORE, InjectedAuthStore } from '../mobx/stores/authStore';
import * as _ from 'lodash';
import { IReactionDisposer, reaction } from 'mobx';
import { InjectedSocketStore, SOCKET_STORE } from '../mobx/stores/socketStore';
import { getCurrencyRateList } from 'currencies-exchange-rates';
import currency from 'currency.js';
import { InjectedUserStore, USER_STORE } from '../mobx/stores/userStore';
import { HoldingGrid } from '../components/holdingGrid/HoldingGrid';
import { HoldingValueDistribution } from '../components/holdingValueDistribution/HoldingValueDistribution';
import { PortfolioBenchmarksComponent } from '../components/portfolioBenchmarks/PortfolioBenchmarks';

interface PortfolioHolding {
  id: string;
  symbol: string;
  latestPrice: number | null;
  currency: string;
  localCurrency: string;
  amount: number;
  netInvestedSum: number;
  netProfit: number;
  stock: any;
  changePercent: number | null;
  totalValue?: number;
  displayLatestPrice?: string;
  displayNetInvested?: string;
  displayTotalValue?: string;
  degiroExchangeKey?: string;
}

interface State {
  portfolioLoading: boolean;
  benchmarks?: {
    marketBenchmarks: any[];
    portfolioBenchmark: {
      periodReturnRate: number;
      dailyPortfolioValue: any[];
    };
  };
}
const euroFormatter = (value) =>
  currency(value, { separator: '.', decimal: ',', symbol: '€ ' });

@inject(AUTH_STORE, SOCKET_STORE, USER_STORE)
@observer
export class PortfolioPage extends InjectedComponent<
  InjectedAuthStore & InjectedSocketStore & InjectedUserStore & {},
  State
> {
  public state: State = {
    portfolioLoading: true,
  };

  public reactions: IReactionDisposer[] = [];
  public portfolioHoldings = [];
  public exchangeRates;
  public degiroImportError = '';

  public async componentDidMount() {
    this.exchangeRates = await getCurrencyRateList();
  }

  public componentWillUnmount() {
    this.reactions.forEach((reaction) => reaction());
  }

  public getPieData(formattedPortfolioHoldings: PortfolioHolding[]) {
    const totalValueSum = _.sum(
      formattedPortfolioHoldings
        .map((holding: PortfolioHolding) => holding.totalValue || 0)
        .filter((v) => v),
    );

    return formattedPortfolioHoldings
      .map((holding: PortfolioHolding) => {
        const percentage =
          (100 / totalValueSum) * (holding.totalValue || 0) || 0;
        return {
          x: holding.symbol,
          y: holding.totalValue || 0,
          percentage,
        };
      })
      .sort((a, b) => {
        if (b.percentage > a.percentage) {
          return -1;
        } else if (a.percentage > b.percentage) {
          return 1;
        }
        return 0;
      });
  }

  public getDailyPortfolioBenchmarkData() {
    if (!this.state.benchmarks) {
      return [];
    }

    let lastValue;

    return this.state.benchmarks.portfolioBenchmark.dailyPortfolioValue.map(
      (daily) => {
        if (daily.value) {
          lastValue = daily.value;
        }

        return {
          x: daily.date,
          y: daily.value || lastValue,
        };
      },
    );
  }

  public render() {
    return (
      <div className="row" style={{ height: '100%' }}>
        <div className="col__7 col__12__m property_block divider">
          <div
            // className="property_block"
            style={{ height: '100%', overflow: 'hidden' }}
          >
            <h3>Eigendommen</h3>
            <div className="table">
              <HoldingGrid />
              <br></br>
            </div>
          </div>
        </div>
        <div className="col__5 col__12__m margin__bs__m property_block">
          <div className="top">
            <h3>Verdeling</h3>
            <div className="x">
              <div className="chart">
                <HoldingValueDistribution />
              </div>
            </div>
          </div>

          <div className="bottom">
            <PortfolioBenchmarksComponent />
          </div>
        </div>
      </div>
    );
  }
}
