import { InjectedComponent } from '../InjectedComponent';
import {
  CellClassParams,
  DataGrid,
  ValueFormatterParams,
} from '@material-ui/data-grid';
import {
  InjectedPortfolioStore,
  PortfolioHolding,
  PORTFOLIO_STORE,
} from '../../mobx/stores/portfolioStore';
import { inject, observer } from 'mobx-react';
import currency from 'currency.js';
import { CircularProgress } from '@material-ui/core';
import s from './HoldingGrid.module.scss';

interface State {
  loading: boolean;
  rows: any[];
}

const euroFormatter = (value) =>
  currency(value, { separator: '.', decimal: ',', symbol: '€ ' });

@inject(PORTFOLIO_STORE)
@observer
export class HoldingGrid extends InjectedComponent<
  InjectedPortfolioStore,
  {},
  State
> {
  public state: State = {
    loading: true,
    rows: [],
  };

  public header = [
    {
      headerName: 'Symbool',
      field: 'symbol',
      width: 90,
      cellClassName: (params: CellClassParams) =>
        params.row.amount === 0 ? 'no_active_holding' : '',
    },
    {
      headerName: 'Prijs',
      field: 'latestPrice',
      width: 100,
      cellClassName: (params: CellClassParams) =>
        params.row.amount === 0 ? 'no_active_holding' : '',
      renderCell(params: ValueFormatterParams) {
        if (!params.value && params.value !== 0) {
          return <span>-</span>;
        } else {
          params.value = parseFloat((+params.value).toFixed(2));

          let symbol = ' ';
          if (params.row.localCurrency === 'EUR') {
            symbol = '€ ';
          } else if (params.row.localCurrency === 'USD') {
            symbol = '$ ';
          }

          const display = currency(params.value, {
            separator: '.',
            decimal: ',',
            symbol,
          }).format();

          return <span>{display}</span>;
        }
      },
    },
    {
      headerName: 'Verandering',
      field: 'changePercent',
      width: 100,
      cellClassName: (params: CellClassParams) =>
        params.row.amount === 0 ? 'no_active_holding' : '',
      renderCell: (params: ValueFormatterParams) => {
        if (params.row.changePercent && params.row.amount > 0) {
          const value = (
            Math.round(Math.abs(params.row.changePercent) * 100) / 100
          ).toFixed(2);
          let className =
            params.row.changePercent === 0
              ? 'neutral'
              : params.row.changePercent! > 0
              ? 'green'
              : 'red';

          if (params.row.changePercent < 0) {
            return <span className={className}>-{value}%</span>;
          } else {
            return <span className={className}>+{value}%</span>;
          }
        }
        return <span></span>;
      },
    },
    {
      headerName: 'Waarde',
      field: 'totalValue',
      width: 120,
      cellClassName: (params: CellClassParams) =>
        params.row.amount === 0 ? 'no_active_holding' : '',
      renderCell: (params: ValueFormatterParams) => {
        const displayTotalValue = euroFormatter(params.value).format();

        return <span>{displayTotalValue}</span>;
      },
    },
    {
      headerName: 'Geïnvesteerd',
      field: 'netInvestedSum',
      width: 120,
      cellClassName: (params: CellClassParams) =>
        params.row.amount === 0 ? 'no_active_holding' : '',
      renderCell: (params: ValueFormatterParams) => {
        let formatted;
        let value = params.row.netInvestedSum;
        if (params.row.netInvestedSum < 0) {
          value = 0;
        }

        if (params.row.currency === 'EUR') {
          formatted = euroFormatter(value).format();
        } else if (params.row.currency === 'USD') {
          formatted = currency(value).format();
        }
        return formatted;
      },
    },
    {
      headerName: 'Winst',
      field: 'profit',
      width: 200,
      cellClassName: (params: CellClassParams) =>
        params.row.amount === 0 ? 'no_active_holding' : '',
      renderCell: (params: ValueFormatterParams) => {
        let percentualGain;

        if (params.row.totalValue > 0) {
          percentualGain = (
            ((params.row.totalValue - params.row.netInvestedSum) /
              params.row.netInvestedSum) *
            100
          ).toFixed(2);
        }

        let className =
          params.value === 0 ? 'neutral' : params.value! > 0 ? 'green' : 'red';

        let display = `${euroFormatter(params.value).format()}`;

        if (percentualGain && params.row.netInvestedSum > 0) {
          display += ` (${percentualGain}%)`;
        }

        return <span className={className}>{display}</span>;
      },
    },
  ];
  public exchangeRates;
  public updateInterval: NodeJS.Timeout;

  public async componentDidMount() {
    this.setState({ rows: [...this.formatPortfolioHoldings()] });

    this.updateInterval = setInterval(() => {
      if (
        this.injected.portfolioStore.holdings.length === 0 ||
        this.injected.portfolioStore.holdings.find(
          (holding) => typeof holding.totalValue !== 'undefined',
        )
      ) {
        this.setState({
          rows: [...this.formatPortfolioHoldings()],
          loading: false,
        });
      }
    }, 2000);
  }

  public componentWillUnmount() {
    clearInterval(this.updateInterval);
  }

  public formatPortfolioHoldings(): PortfolioHolding[] {
    if (!this.state) {
      return [];
    }

    const clone = [...this.injected.portfolioStore.holdings].map((holding) => {
      if (holding.amount === 0) {
        holding.changePercent = undefined;
      }
      return holding;
    });

    return clone.sort((a, b) => {
      const value1 = a.totalValue || 0;
      const value2 = b.totalValue || 0;

      if (value2 > value1) {
        return 1;
      } else if (value2 < value1) {
        return -1;
      }
      return 0;
    });
  }

  public render() {
    return (
      <>
        <div className={s.container}>
          {this.state.loading && (
            <div className={s.loading}>
              <CircularProgress
                style={{ width: 15, height: 15 }}
                color="inherit"
              />
            </div>
          )}
          {!this.state.loading && (
            <DataGrid
              checkboxSelection={false}
              rowHeight={30}
              headerHeight={40}
              rows={this.state.rows}
              columns={this.header}
              loading={this.state.loading}
              pageSize={0}
              hideFooterPagination={true}
              hideFooterRowCount={true}
            />
          )}
        </div>
      </>
    );
  }
}
