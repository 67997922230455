export type ApiDefinition = {
  login: {
    uri: 'login';
    method: 'POST';
    response?: OkDataResponse<{
      expiresIn: number;
      payload: any;
      token: string;
    }>;
    qs?;
    params?;
  } & GenericDefintion &
    Body<{ email: string; password: string }>;
  signup: {
    uri: 'user/signup';
    method: 'POST';
    response?: OkDataResponse<any>;
    qs?;
    params?;
  } & GenericDefintion &
    Body<{ email: string; password: string; captchaToken: string }>;
  me: {
    uri: 'user/me';
    method: 'GET';
    response?: OkDataResponse<{}>;
    qs?;
    body?;
    params?;
  } & GenericDefintion;
  onboard: {
    uri: 'user/onboard';
    method: 'POST';
    response?: OkDataResponse<{}>;
    qs?;
    body?;
    params?;
  } & GenericDefintion;
  degiroImport: {
    uri: 'stock/degiro-import';
    method: 'POST';
    response?: OkDataResponse<{}>;
    qs?;
    params?;
  } & GenericDefintion &
    Body<any>;
};

export type Body<T> = {
  body: T;
};

export type Qs<T> = {
  qs: T;
};

export type Params<T> = {
  params: T;
};

export type GenericDefintion = {
  auth?: boolean;
};

export const defaultDefinition: {
  method: 'GET';
  auth: true;
  body: any;
  params: any;
} = {
  auth: true,
  method: 'GET',
  body: {},
  params: {},
};

export const apiDefinition: ApiDefinition = {
  login: {
    ...defaultDefinition,
    uri: 'login',
    method: 'POST',
    auth: false,
  },
  signup: {
    ...defaultDefinition,
    uri: 'user/signup',
    method: 'POST',
    auth: false,
  },
  me: {
    ...defaultDefinition,
    uri: 'user/me',
  },
  onboard: {
    ...defaultDefinition,
    uri: 'user/onboard',
    method: 'POST',
  },
  degiroImport: {
    ...defaultDefinition,
    uri: 'stock/degiro-import',
    method: 'POST',
  },
};

export type Method = 'POST' | 'GET' | 'DELETE' | 'PUT';

// export type GenericDefinition<T> = T & {
//   uri: string;
//   method: Method;
//   auth?: boolean;
//   body?: Pick<T, 'body'>;
//   params?: any;
// };

export type Route = keyof ApiDefinition;

export class ErrorResponse {
  status: 'error';
  message: string;
}

export class OkResponse {
  status: 'ok';
}

export class OkDataResponse<T> extends OkResponse {
  data: T;
}
