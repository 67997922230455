import { Component } from 'react';

export class InjectedComponent<
  InjectedProps = {},
  Props = {},
  State = {}
> extends Component<
  Props & { ref?: (ref: null | WrappedComponent<any, Props>) => void },
  State
> {
  get injected() {
    return (this.props as any) as InjectedProps;
  }
}

export type WrappedComponent<Component, Props> = {
  wrappedComponent: React.ComponentClass<Props>;
  wrappedInstance: Component;
};
