import { action, computed, makeObservable, observable } from 'mobx';

class UserStore {
  @observable
  refreshingUser: boolean = false;

  @observable
  initialized: boolean = false;

  @observable
  private _user: any | undefined;

  @computed
  get hasUser() {
    return !!this._user;
  }

  @computed
  get activeWorkspaceDisplayName() {
    if (this.user) {
      return this.user.activeWorkspace.displayName;
    }
  }

  @computed
  get user() {
    return this._user;
  }

  @action
  setUser(user: any | undefined) {
    this._user = user;
  }

  @action
  setRefreshingUser(refreshingUser: boolean) {
    this.refreshingUser = refreshingUser;
  }

  @action
  setInitialized() {
    this.initialized = true;
  }

  constructor() {
    makeObservable(this);
  }
}

export const userStore = new UserStore();
export const USER_STORE = 'userStore';

export interface InjectedUserStore {
  userStore: UserStore;
}
