import { Socket as BaseSocket } from './common/Socket';

export class WebSocket extends BaseSocket {
  constructor(
    endpoint,
    events: {
      onConnect: () => void;
      onDisconnect: () => void;
    },
    options?: {},
  ) {
    super(endpoint, events, options);
  }
}
