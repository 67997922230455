import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { InjectedUserStore, USER_STORE } from '../../mobx/stores/userStore';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Backdrop, Button, CircularProgress } from '@material-ui/core';
import { InjectedComponent } from '../InjectedComponent';
import { DegiroFileImport } from '../deGiroFileImport/DeGiroFileImport';
import { updateOnboarding, updateUser } from '../../services/user';
import { AUTH_STORE, InjectedAuthStore } from '../../mobx/stores/authStore';

interface State {
  open: boolean;
  failed: boolean;
  failedAgain: boolean;
  loading: boolean;
}

@inject(USER_STORE, AUTH_STORE)
@observer
export class Onboarding extends InjectedComponent<
  InjectedUserStore & InjectedAuthStore,
  {},
  {}
> {
  public state: State = {
    open: false,
    failed: false,
    failedAgain: false,
    loading: false,
  };

  constructor(props) {
    super(props);
  }

  public async handleClose() {
    this.setState({ failed: false, failedAgain: false });
    // await this.setOnboarding();
  }

  public async setOnboarding() {
    await updateOnboarding(this.injected.authStore.token);
    await updateUser(this.injected.authStore.token);
  }

  render() {
    return (
      <>
        {!this.state.loading && (
          <>
            {this.state.failed && (
              <Dialog open={this.state.failed}>
                <DialogTitle id="form-dialog-title">
                  Er is iets mis gegaan..
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {!this.state.failedAgain && (
                      <>
                        {' '}
                        We konden uw transactie export helaas niet verwerken.{' '}
                        <br></br>
                        Heeft u al een kijkje genomen in onze{' '}
                        <a
                          href="https://support.stockdash.nl/kb/guide/nl/degiro-transacties-importeren-AO8A1iVzb7/Steps/137826"
                          target="blank"
                        >
                          handleiding
                        </a>
                        ? <br />U kunt het met de orangje knop nog een keer
                        proberen.
                      </>
                    )}
                    {this.state.failedAgain && (
                      <>
                        Het is wederom niet gelukt om transacties te verwerken.
                        <br></br>
                        We zouden het zeer op prijs stellen als u ons een
                        seintje geeft via{' '}
                        <a target="blank" href="https://support.stockdash.nl">
                          support
                        </a>
                        !
                      </>
                    )}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={async () => this.handleClose()}
                    color="primary"
                  >
                    Sluiten
                  </Button>
                  {!this.state.failedAgain && (
                    <DegiroFileImport
                      hideStatus={true}
                      onClick={() => this.setState({ loading: true })}
                      onFailure={() => {
                        this.setState({ failedAgain: true, loading: false });
                      }}
                      onSuccess={async () => {
                        this.setState({ loading: false });
                        await this.setOnboarding();
                      }}
                    ></DegiroFileImport>
                  )}
                </DialogActions>
              </Dialog>
            )}

            {!this.state.failed && (
              <Dialog
                open={!this.injected.userStore.user.onboarded}
                onClose={() => this.handleClose}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle id="form-dialog-title">
                  Welkom belegger!
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Bedankt dat u onze portfolio tracker wilt uitproberen.
                    <br />U behoort tot één van deze eerste gebruikers van onze
                    web applicatie. <br /> <br />
                    Om van start te gaan kunt u uw transacties importeren vanuit
                    DeGiro. <br />
                    Dit kan direct met de onderstaande oranje knop.
                    <br />{' '}
                    <a
                      href="https://support.stockdash.nl/kb/guide/nl/degiro-transacties-importeren-AO8A1iVzb7/Steps/137826"
                      target="blank"
                    >
                      Hoe importeer ik mijn transacties uit DeGiro?
                    </a>
                    <br></br>
                    <br />
                    Wij luisteren graag naar uw{' '}
                    <a
                      href="https://support.stockdash.nl/kb/guide/nl/deel-uw-idee-WJKLfkp3ug/Steps/137915"
                      target="blank"
                    >
                      feedback
                    </a>
                    . <br />
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  {/* <Button
                    onClick={async () => this.handleClose()}
                    color="primary"
                  >
                    Sluiten
                  </Button> */}
                  <DegiroFileImport
                    hideStatus={true}
                    onClick={() => this.setState({ loading: true })}
                    onFailure={() => {
                      this.setState({ failed: true, loading: false });
                    }}
                    onSuccess={async () => {
                      this.setState({ loading: false });
                      await this.setOnboarding();
                    }}
                  ></DegiroFileImport>
                </DialogActions>
              </Dialog>
            )}
          </>
        )}

        <Backdrop style={{ zIndex: 99 }} open={this.state.loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    );
  }
}
