import React from 'react';
import { VictoryLabel, VictoryLabelProps, VictoryTooltip } from 'victory';

interface Props extends VictoryLabelProps {
  active?: boolean;
  orientation?: any;
  datum?: any;
}

class CustomLabel extends React.Component<Props> {
  render() {
    let labelHidden = false;

    if (this.props.datum.percentage < 2) {
      labelHidden = true;
    }

    const toolTipLabel = `${
      this.props.text
    } \n ${this.props.datum.percentage.toFixed(2)}%`;

    return (
      <g>
        <VictoryLabel
          {...this.props}
          style={{ ...this.props.style, color: 'white' }}
          text={this.props.active || labelHidden ? '' : this.props.text}
        />
        <VictoryTooltip
          renderInPortal
          text={toolTipLabel}
          style={{ ...this.props.style, fontWeight: 'bold' }}
          active={this.props.active}
          x={this.props.x}
          y={this.props.y}
          orientation={this.props.orientation}
          flyoutStyle={{
            fill: 'white',
            strokeWidth: 0,
            paddingLeft: 20,
            paddingRight: 20,
          }}
        />
      </g>
    );
  }
}

(CustomLabel as any).defaultEvents = VictoryTooltip.defaultEvents;

export { CustomLabel };
