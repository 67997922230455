import React, { Component } from 'react';
import './App.scss';
import { Provider } from 'mobx-react';
import Init from './Init';
import { stores } from './mobx';
import { Main } from './Main';
import { Route, Switch, Link } from 'react-router-dom';
import { SignupPage } from './pages/SignupPage';

export class App extends Component {
  render() {
    return (
      <Provider {...stores}>
        <Init>
          <Switch>
            <Route
              exact
              path="/signup"
              render={({ history }) => (
                <SignupPage history={history}></SignupPage>
              )}
            />
            <Route exact path="*" component={Main} />
          </Switch>
          {/* <Main /> */}
        </Init>
      </Provider>
    );
  }
}
