import {
  VictoryContainer,
  VictoryLabel,
  VictoryPie,
  VictoryTooltip,
} from 'victory';
import * as _ from 'lodash';
import { InjectedComponent } from '../../InjectedComponent';
import { CustomLabel } from './CustomLabel';

interface State {}

interface Props {
  data: any[];
}

export class PieChart extends InjectedComponent<{}, Props, State> {
  public state: State = {};

  public initialized = false;
  public updateInterval: NodeJS.Timeout;

  public componentDidMount() {}

  public componentWillUnmount() {}

  public render() {
    const typogrophy = {
      fontFamily: 'Arial, Helvetica, sans-serif',
      padding: 5,
      fontSize: 12,
    };

    const colorScale = [
      '#325ba8',
      '#FF9F1C',
      '#FFBF69',
      '#CBF3F0',
      '#2EC4B6',
      'navy',
    ];

    return (
      <VictoryPie
        containerComponent={<VictoryContainer responsive={true} />}
        style={{
          labels: {
            fontSize: 12,
          },
        }}
        height={250}
        padding={
          {
            // top: 20,
            // left: 20,
            // right: 20,
            // bottom: 20,
          }
        }
        labelComponent={
          <CustomLabel style={{ ...typogrophy }} renderInPortal />
        }
        //  <VictoryLabel renderInPortal style={{ ...typogrophy }} />

        labelRadius={({ innerRadius }) => {
          if (typeof innerRadius === 'number') {
            return (innerRadius! as any) + 85;
          }
        }}
        colorScale={colorScale}
        innerRadius={50}
        animate={{
          duration: 1000,
        }}
        data={this.props.data}
      />
    );
  }
}
