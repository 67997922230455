import { authStore } from './stores/authStore';
// import { navigationStore } from './stores/navigationStore';
import { userStore } from './stores/userStore';
import { socketStore } from './stores/socketStore';
import { portfolioStore } from './stores/portfolioStore';

import './reactions/user';
import './reactions/auth';
import './reactions/portfolio';
import './reactions/prices';

export const stores = {
  authStore,
  userStore,
  socketStore,
  portfolioStore,
  //   navigationStore,
};
