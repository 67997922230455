import { reaction } from 'mobx';
import { socket } from '../../lib/socket';
import { subscribeRealtimePrices, Ticker } from '../../services/prices';
import { portfolioStore } from '../stores/portfolioStore';
import { socketStore } from '../stores/socketStore';
import { getCurrencyRateList } from 'currencies-exchange-rates';

let exchangeRates;

reaction(
  () => socketStore.isConnected,
  async (isConnected) => {
    if (isConnected) {
      if (!exchangeRates) {
        exchangeRates = await getCurrencyRateList();
      }

      await socket.on('price', async (tick: Ticker) => {
        portfolioStore.updateHoldingPriceByTick(tick, exchangeRates);
      });

      await subscribeRealtimePrices();
    }
  },
);
