import { action, makeObservable, observable } from 'mobx';
import { Ticker } from '../../services/prices';

export interface PortfolioHolding {
  amount: number;
  currency: string;
  id: string;
  stock: {
    id: string;
    yahooAssetProfile: {
      sector: string | null;
      industry: string | null;
    };
  };
  symbol: string;
  netInvestedSum: number;
  localCurrency: string;
  netProfit: number;
  // Added by realtime price updates
  latestPrice?: number;
  changePercent?: number;
  totalValue?: number; // In users currency
  profit?: number;
  isAfterHours?: boolean;
}

export interface BenchmarkPerDay {
  date: string;
  portfolioWeightedReturnRate: number;
  returnRate: number;
  totalValueInEur: number;
  netTransactionSumInEur: number;
  transactionIds: string[];
  transactions: any[];
}

export interface Benchmarks {
  portfolioBenchmark: {
    perDay: BenchmarkPerDay[];
    periodReturnRate: number;
  };
  marketBenchmarks: any[];
}

class PortfolioStore {
  @observable
  holdings: PortfolioHolding[] = [];

  @observable
  benchmarks: Benchmarks;

  @action
  setHoldings(holdings: PortfolioHolding[]) {
    this.holdings = [...holdings];
  }

  @action
  setBenchmarks(benchmarks: Benchmarks) {
    this.benchmarks = benchmarks;
  }

  @action.bound
  updateHoldingPriceByTick(tick: Ticker, exchangeRates) {
    const holding = portfolioStore.holdings.find(
      (holding) => holding.stock.id === tick.stockId,
    );
    if (holding) {
      holding.latestPrice = tick.price;
      holding.changePercent = tick.changePercent;

      let totalValue;
      let profit = 0;

      if (holding.amount > 0) {
        totalValue =
          holding.latestPrice !== null && holding.latestPrice !== undefined
            ? holding.amount * +holding.latestPrice
            : holding.netInvestedSum;

        let convertedTotalValue = totalValue;
        // Convert if localCurrency is not in the users currency
        if (totalValue && holding.localCurrency !== 'EUR') {
          convertedTotalValue =
            totalValue / exchangeRates.rates[holding.localCurrency];
          totalValue = convertedTotalValue;
        }

        holding.totalValue = totalValue;
        profit = convertedTotalValue - holding.netInvestedSum;
      }

      if (holding.netProfit) {
        profit = holding.netProfit;
      }

      holding.profit = profit;
    }
  }

  constructor() {
    makeObservable(this);
  }
}

export const portfolioStore = new PortfolioStore();
export const PORTFOLIO_STORE = 'portfolioStore';

export interface InjectedPortfolioStore {
  portfolioStore: PortfolioStore;
}
