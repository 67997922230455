import { socket } from '../lib/socket';

export interface Ticker {
  stockId: string;
  yahooSymbol: string;
  price: number;
  exchangeKey: string;
  lastUpdated: any;
  changePercent: number;
}

export async function subscribeRealtimePrices() {
  await socket.emit('subscribeRealtimePrices');
}
