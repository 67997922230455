import { Api as BaseApi, ApiRequestOptions } from './common/Api';
import { Method } from './common/definition';
import axios from 'axios';

export class Api extends BaseApi {
  constructor(baseUrl: string) {
    super(baseUrl);
  }

  protected async _req(
    url: string,
    method: Method,
    options: ApiRequestOptions,
  ) {
    let headers: { Authorization?: string } = {};

    if (options.token && options.tokenMethod === 'HEADER') {
      headers['Authorization'] = `Bearer ${options.token}`;
    }

    try {
      const result = await axios.request({
        url,
        method,
        data: options.body,
        params: options.qs,
        headers,
      });

      return result.data;
    } catch (e) {
      throw e.response;
    }
  }
}
