import io from 'socket.io-client';
import {
  SocketDefinition,
  SocketResponse,
  SocketErrorResponse,
  socketDefinition,
} from './definition';

export class Socket {
  protected socket: any | undefined;

  constructor(
    protected endpoint: string,
    protected events: {
      onConnect: () => void;
      onDisconnect: () => void;
    },
    protected options?: {},
  ) {
    if (!this.endpoint || this.endpoint === '') {
      throw new Error('Endpoint is undefined');
    }
  }

  public async connect(namespace?: string, token?: string) {
    const connectionString = `${this.endpoint}${
      namespace ? '/' + namespace : ''
    }`;
    const addQuery = token ? { query: `token=${token}` } : {};

    this.socket = io(connectionString, {
      ...addQuery,
      transports: ['websocket'],
      forceNew: true,
      multiplex: false,
    });

    this.socket.on('connect_error', () => {
      this.events.onDisconnect();
      return;
    });
    this.socket.on('connect', () => {
      if (this.events.onConnect) {
        this.events.onConnect();
      }
      return;
    });
    this.socket.on('disconnect', () => {
      if (this.events.onDisconnect) {
        this.events.onDisconnect();
      }
      return;
    });

    this.socket.connect();
  }

  public async disconnect() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = undefined;
    }
  }

  public async emit<T extends keyof SocketDefinition>(
    route: T,
    data?: any,
  ): Promise<any> {
    const emitDefinition = socketDefinition[route];

    return new Promise(async (resolve, reject) => {
      if (!this.socket) {
        return reject('Socket is undefined');
      }
      this.socket.emit(
        emitDefinition.message,
        data,
        (response: SocketResponse<any> | SocketErrorResponse) => {
          if (response.status === 'ok') {
            resolve(response.data);
          } else {
            reject(response);
          }
        },
      );
    });
  }

  public on(message: string, callback: any) {
    if (!this.socket) {
      throw new Error('Socket is undefined');
    }
    return this.socket.on(message, callback);
  }

  public off(message: string, fn: (respone: any) => void) {
    if (!this.socket) {
      throw new Error('Socket is undefined');
    }
    return this.socket.off(message, fn);
  }
}
